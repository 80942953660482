@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://telegram.org/css/telegram-web-app.css');
@font-face {
  font-family: 'ProductSans';
  src: url('../../public/fonts/ProductSansRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Product Sans Bold */
@font-face {
  font-family: 'ProductSans';
  src: url('../../public/fonts/ProductSansBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'ProductSans', 'TGLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

.text-gradient {
    background: linear-gradient(45deg, #ff6f61, #d77bf7);
    
    color: transparent;
}
  
.collection-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .collection-container {
    justify-content: center;
  }
}

/* Меньшие размеры для карточек */
.collection-card {
  width: 45%;  
  max-width: 300px; 
}

@media (max-width: 768px) {
  .collection-card {
    width: 100%; 
  }
}

/* Скрываем scrollbar для Chrome, Safari и Edge */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Скрываем scrollbar для Firefox и IE */
.no-scrollbar {
  -ms-overflow-style: none;  
  scrollbar-width: none;     
}


/* Для Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #656565 rgb(75, 70, 70);
}

.bg-select-arrow {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23B0B0B0'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
}