.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
  
  .loader {
    width: 64px;
    height: 64px;
    border: 8px solid rgba(200,200,200,0.3);
    border-top-color: #3498db;
    border-radius: 50%;
    animation: spin 1.5s ease-in-out infinite;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  